<template>
  <div id="app">
    <div class="mainUrl" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <a href="https://3338530.cc" target="_self">
        <img :src="mainUrl" alt="" />
      </a>
    </div>
    <div class="serveUrl" @mouseover="moveIn" @mouseleave="moveOut">
      <a
        href="https://q1i5xu6syy.m3e3tmhe.com/711ed61231913e9d560152jkfle-keli1e11e7d8691427ddd70aa7da447fb727b874de7ecd34b35dc5b36b1af0ca929c"
        target="_self"
      >
        <img :src="serveUrl" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainUrl: require("./assets/fangwen-off.png"),
      // mainUrlOn: require("./assets/fangwen-on.png"),
      serveUrl: require("./assets/kefu-off.png"),
      // serveUrlOn: require("./assets/kefu-on.png"),
    };
  },
  methods: {
    mouseOver() {
      this.mainUrl = require("./assets/fangwen-on.png");
    },
    mouseLeave() {
      this.mainUrl = require("./assets/fangwen-off.png");
    },
    moveIn() {
      this.serveUrl = require("./assets/kefu-on.png");
    },
    moveOut() {
      this.serveUrl = require("./assets/kefu-off.png");
    },
  },
};
</script>

<style>
html,body{
   background: #393939;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.mainUrl {
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 12%;
}
.serveUrl {
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 20px;
}
@media (max-width: 749px) {
  .mainUrl img {
    margin-top: 30%;
    width: 80%;
    margin-left: 10%;
  }
  .serveUrl img {
    width: 60%;
    margin-left: 20%;
  }
}
</style>
